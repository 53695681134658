import React from 'react';
import { FadIcon } from 'src/interface/vendors/fa-wrapper';
import { Lazyload } from 'src/interface/vendors/lazyload-wrapper';

const Swipe = ({ background, className, children }) => (
  <section className={`swipe-block ${className}`}>
    {children}
    <Lazyload>
      <div className='swipe-bg' style={{backgroundImage: `url(${background}`}} />
    </Lazyload>
  </section>
)

const SwipeBody = ({ className = '', children }) => (
  <div className={`swipe-body ${className}`}>
    <div className='swipe-body-content'>{children}</div>
    <div className='swipe-body-bg' />
  </div>
)

const SwipePreTitle = ({ className = '', children }) => (
  <h6 className={`swipe-pretitle ${className}`}>
    {children}
  </h6>
)

const SwipeTitle = ({ className = '', children }) => (
  <h3 className={`swipe-title ${className}`}>
    {children}
  </h3>
)

const SwipeSubtitle = ({ className = '', children }) => (
  <h5 className={`swipe-subtitle ${className}`}>
    {children}
  </h5>
)

const SwipeText = ({ className = '', children }) => (
  <h5 className={`swipe-text ${className}`}>
    {children}
  </h5>
)

const SwipeBtns = ({ className = '', children }) => (
  <div className={`swipe-btns ${className}`}>
    {children}
  </div>
)

const SwipeOverlay = ({ className = '', children }) => (
  <div className={`swipe-overlay ${className}`}>
    {children}
  </div>
)

export {
  Swipe,
  SwipeBody,
  SwipePreTitle,
  SwipeTitle,
  SwipeSubtitle,
  SwipeText,
  SwipeBtns,
  SwipeOverlay
}

import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import IconArticle from 'src/interface/articles/icon-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { BtnLink, NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { SwissArmyKnife } from 'src/assets';

const IndexSectionWhat = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='what' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col md={12} lg={{span: 6, order: 2}}>
              <div className='mb-5'>
                <SectionPretitle>What we do</SectionPretitle>
                <SectionTitle>Your swiss army knife</SectionTitle>
                <SectionText>
                  Our team honed our skills working on startups where we learned how to design, build and scale platforms that just work.
                  With that comes the ability and knowledge to avoid common pitfalls while staying laser-focused on your north star.
                </SectionText>
                <SectionBtns>
                  <NextLink to='/about' className='btn-text-primary'>Learn More</NextLink>
                </SectionBtns>
              </div>
            </Col>
            <Col md={12} lg={{span: 6, order: 1}}>
              <div className=''>
                <SwissArmyKnife className='absolute top-0 fill-gray-50' />
                <div className='relative z-1'>
                  <Row>
                    <Col xs={6}>
                      <IconArticle icon='store' title='Marketplaces' className='mb-5'/>
                    </Col>
                    <Col xs={6}>
                      <IconArticle icon='user-friends' title='Social Networking' className='mb-5'/>
                    </Col>
                    <Col xs={6}>
                      <IconArticle icon='stream' title='Data Aggregation' className='mb-5'/>
                    </Col>
                    <Col xs={6}>
                      <IconArticle icon='brackets-curly' title='API Services' className='mb-5'/>
                    </Col>
                    <Col xs={6}>
                      <IconArticle icon='taxi' title='On-Demand' className='mb-5'/>
                    </Col>
                    <Col xs={6}>
                      <IconArticle icon='drone-alt' title='Internet of Things' className='mb-5'/>
                    </Col>
                    <Col xs={6}>
                      <IconArticle icon='tachometer-alt-fast' title='Software as a Service' className='mb-5'/>
                    </Col>
                    <Col xs={6}>
                      <IconArticle icon='browser' title='Platform as a Service' className='mb-5'/>
                    </Col>
                    <Col xs={6}>
                      <IconArticle icon='chart-network' title='Data Integration'/>
                    </Col>
                    <Col xs={6}>
                      <IconArticle icon='lightbulb' title='Other Ideas'/>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default IndexSectionWhat;

import React, { Fragment, useEffect, useState } from 'react';
import MktRoute from 'src/interface/routes/mkt-route';
import MktNav from 'src/interface/navs/mkt-nav';
import MktPrefooter from 'src/interface/prefooters/mkt-prefooter';
import MktFooter from 'src/interface/footers/mkt-footer'
import IndexHeader from 'src/components/index/index-header';
import IndexSplash from 'src/components/index/index-splash';
import IndexSectionWhat from 'src/components/index/index-section-what';
import IndexSectionWho from 'src/components/index/index-section-who';
import IndexSectionProcess from 'src/components/index/index-section-process';
import IndexSectionDeliver from 'src/components/index/index-section-deliver';
import IndexSectionWhy from 'src/components/index/index-section-why';
import IndexSectionWork from 'src/components/index/index-section-work';

const IndexPage = () => {
  const [ intro, setIntro ] = useState(true);


  // Hooks
  useEffect(() => {
    return () => setIntro(true);
  }, [])


  // Render
  return (
    <MktRoute title='Mile Hi Labs | Elevated Software'>
      <IndexSplash intro={intro} setIntro={v => setIntro(v)} />
      <MktNav />
      <IndexHeader />
      <main className='mvh-50'>
        <IndexSectionWhat />
        <IndexSectionWho />
        <IndexSectionProcess />
        <IndexSectionDeliver />
        <IndexSectionWhy />
        <IndexSectionWork />
        <MktPrefooter />
      </main>
      <MktFooter />
    </MktRoute>
  )
}

export default IndexPage;

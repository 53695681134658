import React, { Fragment, useEffect, useState } from 'react';
import AnimeWrapper from 'src/interface/vendors/anime-wrapper';
import { Triangle, Circle, Square, Line } from 'src/assets';
import { timeout } from 'src/utils/helpers';

const TextAnimation = ({ className, children }) => {
  const options = ['Tech', 'Software', 'Business', 'Design', 'Experiences', 'Operations', 'Apps', 'Products'];
  const [ text, setText ] = useState('Tech');
  const [ animation, setAnimation ] = useState('fade-in-up');


  // Hooks
  useEffect(() => {
    console.log('text: ', text);
  }, [text])


  // Methods
  async function changeText() {
    setAnimation('fade-out-up');
    await timeout(300);
    loopText(text);
    setAnimation('fade-in-up');
  }

  async function loopText(currentText) {
    let index = options.indexOf(currentText);
    options.length == (index + 1) ? setText(options[0]) : setText(options[index += 1])
  }


  // Render
  return (
    <div className={className}>
      <div className={`animate ${animation}`}>{text}</div>
      <svg xmlns="http://www.w3.org/2000/svg" width='100%' height='5' viewBox='0 0 200 3' className='h-5 w-100 absolute left-0 bottom-0 stroke-primary'>
        <AnimeWrapper
          delay={300}
          duration={3000}
          scaleX={[0, 1]}
          opacity={[1, 1]}
          easing='easeOutSine'
          complete={() => changeText()}
          svg>
          <line x2='100%' fill='' strokeLinecap="square" strokeWidth='5' className='h-5 w-100'/>
        </AnimeWrapper>
      </svg>
    </div>
  )
};

export default TextAnimation;

import React from 'react';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Header, HeaderInner, HeaderBg, HeaderBody, HeaderPretitle, HeaderTitle, HeaderSubtitle, HeaderText, HeaderBtns, HeaderOverlay } from 'src/interface/basics/headers';
import TextAnimation from 'src/interface/animations/text-animation';
import { BtnNextLink, NextLink } from 'src/interface/basics/links';
import { GCollab } from 'src/assets';

const IndexHeader = () => {

  return (
    <Header>
      <HeaderBg background={GCollab} className='py-8'>
        <Container className='lg'>
          <Row>
            <Col lg={7}>
              <div className='flex-center'>
                <HeaderBody className='animate fade-in-up delay-200'>
                  <HeaderPretitle className='primary'>Welcome</HeaderPretitle>
                  <HeaderTitle className='flex-start'>
                    <span>Elevated</span>
                    <TextAnimation className='relative ml-3' />
                  </HeaderTitle>
                  <HeaderText>
                    We're a software development team based in Denver Colorado that specializes in web, mobile, and cloud computing apps.
                    If you can dream it, we can build it. So, let's get to work.
                  </HeaderText>
                  <HeaderBtns className='animate fade-in-down delay-400'>
                    <BtnNextLink to='/contact' className='btn-opaque-white btn-lg'>Contact Us</BtnNextLink>
                    <NextLink to='/about' className='btn-text-white btn-lg ml-3'>Learn More</NextLink>
                  </HeaderBtns>
                </HeaderBody>
              </div>
            </Col>
          </Row>
        </Container>
      </HeaderBg>
    </Header>
  )
}

export default IndexHeader;

import React, { useState } from 'react';
import { withSwipe } from 'src/contexts/swipe-context';
import { Fade } from 'src/interface/basics/animations';
import { TextPrevButton, TextNextButton } from 'src/interface/basics/buttons';
import { Swipe, SwipeBody, SwipePreTitle, SwipeTitle, SwipeSubtitle, SwipeText, SwipeBtns, SwipeOverlay } from 'src/interface/basics/swipes';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { BtnNextLink, NextLink } from 'src/interface/basics/links';

const MktSwipe = (props) => {
  const { show, swipe, swipeContext, className = '', children } = props;
  const { state, changeSwipe } = swipeContext;
  const [ animation, setAnimation ] = useState('');

  return (
    <Fade show={show && state == 'enter'} onEntering={() => setAnimation('fade-in')} onExiting={() => setAnimation('fade-out')}>
      <Swipe background={swipe.photo && swipe.photo.url} className={`py-10 animate ${animation} ${className}`}>
        <Container className='lg relative z-20'>
          <Row>
            <Col sm={12} md={7}>
              <div className='flex-center'>
                <SwipeBody className={`sb-gray-300 animate ${animation ? 'fade-in-up' : 'fade-out-up'}`}>
                  <SwipePreTitle className='primary'>Case Study</SwipePreTitle>
                  <SwipeTitle className='gray-800'>{swipe.title}</SwipeTitle>
                  <SwipeSubtitle className='gray'>{swipe.subtitle}</SwipeSubtitle>
                  <SwipeText className='gray-700'>{swipe.description}</SwipeText>
                  <SwipeBtns>
                    <BtnNextLink to={`/work/${swipe.slug}`}>Learn More</BtnNextLink>
                  </SwipeBtns>
                </SwipeBody>
              </div>
            </Col>
          </Row>
        </Container>
        <SwipeBtns className='absolute flex-end px-3 bottom-1rem w-100 z-20'>
          <TextPrevButton onClick={() => changeSwipe('prev')}>Prev</TextPrevButton>
          <TextNextButton onClick={() => changeSwipe('next')}>Next</TextNextButton>
        </SwipeBtns>
        <SwipeOverlay />
      </Swipe>
    </Fade>
  )
}

export default withSwipe(MktSwipe);

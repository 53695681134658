import React from 'react';
import { FadIcon } from 'src/interface/vendors/fa-wrapper';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';

const ListArticle = ({ photo, icon, title, description, className = '', children }) => {

	return (
		<article className={`list-article ${className}`}>
			<div className='list-article-header'>
				{photo && <LazyImg src={photo} alt={title + ' icon photo'} className='list-article-photo'/>}
				{icon && <FadIcon icon={icon} className='list-article-icon'/>}
				{title && <h5 className='ml-4 mbold gray-800'>{title}</h5>}
			</div>
			<div className='list-article-body'>
				{description && <p className='gray-700'>{description}</p>}
				{children}
			</div>
		</article>
	)
}

export default ListArticle;

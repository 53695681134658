import React from 'react';
import { FadIcon } from 'src/interface/vendors/fa-wrapper';

const SplashWrapper = ({ className = '', children }) => (
  <div className={`splash-wrapper ${className}`}>
    {children}
  </div>
)

const Splash = ({ className = '', children }) => (
  <div className={`splash-block ${className}`}>
    {children}
  </div>
)

export { SplashWrapper, Splash }

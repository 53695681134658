import React, { useState } from 'react';
import { SwipeProvider } from 'src/contexts/swipe-context';
import useSiteMetadata from 'src/hooks/work-hook';
import MktSwipe from 'src/interface/swipes/mkt-swipe';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { BtnLink, NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';

const IndexSectionWork = ({ className = '' }) => {
  const portfolios = useSiteMetadata();
  const swipes = portfolios.sort((a, b) => a.position - b.position);
  const [ animation, setAnimation ] = useState('');
	const [ swipe, setSwipe ] = useState(swipes.find(p => p.position == 1));
	const [ state, setState ] = useState('enter');


	// Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in')}>
      <section id='work' className={`pt-8 animate ${animation} ${className}`}>
				<SwipeProvider show={true} state={state} swipe={swipe} swipes={swipes} setState={setState} setSwipe={setSwipe}>
					{swipes.map(currentSwipe => (
						<MktSwipe
							key={currentSwipe.id}
							show={currentSwipe.position == swipe.position}
							swipe={currentSwipe}
						/>
					))}
			  </SwipeProvider>
		  </section>
	  </Waypoint>
  )
}

export default IndexSectionWork;

import React, { useEffect, useState } from 'react';
import { Fade } from 'src/interface/basics/animations';
import { SplashWrapper, Splash } from 'src/interface/basics/splashes';
import LogoAnimation from 'src/interface/animations/logo-animation';

const IndexSplash = ({ intro, setIntro }) => {
  const [ animation, setAnimation ] = useState('');


  // Render
  return (
    <Fade show={intro} onExiting={() => setAnimation('fade-out')}>
      <SplashWrapper className={animation}>
        <LogoAnimation nextAction={() => setIntro(false)} />
      </SplashWrapper>
    </Fade>
  )
}

export default IndexSplash;

import React, { useEffect, useState } from 'react';
import { timeout } from 'src/utils/helpers';

const SwipeContext = React.createContext();

const SwipeProvider = (props) => {
	const { loop, swipe, setSwipe, swipes, children } = props;
  const [ state, setState ] = useState('enter');


  // Hooks
  useEffect(() => {
    loop && loopSwipes();
  }, [swipe])


  // Methods
  async function loopSwipes() {
    await timeout(10000);
    changeSwipe('next');
  }

  async function changeSwipe(direction) {
    changeState('exit');
    await timeout(300);
    setSwipe(newSwipe(direction));
    changeState('enter');
    console.log('newSwipe: ', newSwipe());
  }

  function changeState(state) {
    setState(state);
    console.log('swipeState: ', state);
  }

  function newSwipe(direction) {
    return direction == 'next' ? nextSwipe() : prevSwipe();
  }

  function prevSwipe() {
    let index = swipes.indexOf(swipe);
    return index == 0 ? swipes[swipes.length - 1] : swipes[index - 1]
  }

  function nextSwipe() {
    let index = swipes.indexOf(swipe);
    return swipes.length == (index + 1) ? swipes[0] : swipes[index + 1]
  }


  // Render
  return (
    <SwipeContext.Provider value={{ swipe, state, changeSwipe, prevSwipe, nextSwipe }}>
      {children}
    </SwipeContext.Provider>
  );
};


const withSwipe = function(WrappedComponent) {
	return (props) => (
		<SwipeContext.Consumer>
			{context => <WrappedComponent swipeContext={context} {...props} />}
		</SwipeContext.Consumer>
	)
}

export { SwipeProvider, withSwipe }

import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import ListArticle from 'src/interface/articles/list-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { BtnLink, NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { Globe } from 'src/assets';

const IndexSectionWhy = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='why' className={`py-5 animate ${animation} ${className}`}>
        <SectionBody>
          <Container>
            <Row className='flex-center'>
              <Col sm={12} md={{span: 6, offset: 1, order: 2}}>
                <div className='mb-5'>
                  <SectionPretitle>Why us</SectionPretitle>
                  <SectionTitle className='mxw-80'>Driven by passion</SectionTitle>
                  <SectionText>
                    Like any artist, we treat every project as an opportunity to produce our greatest work yet
                    because we're passionate about what we do and want our work to be shared.
                    So, rest assured, we'll always go the extra mile to ensure we exceed your expectations and our own.
                  </SectionText>
                  <SectionBtns>
                    <NextLink to='/about' className='btn-text-primary'>Learn More</NextLink>
                  </SectionBtns>
                </div>
              </Col>
              <Col sm={12} md={{span: 5, order: 1}}>
                <Globe className='absolute fill-gray-200 o-700'/>
                <div className='relative z-1'>
                  <ListArticle
                    icon='fire'
                    title='Exceptional Performance'
                    description="Everything we build uses modern technology we've already vetted so you get top-notch performance from first paint to critical processes."
                    className='mb-4'
                  />
                  <ListArticle
                    icon='globe'
                    title='99.9% Uptime'
                    description="If we built your cloud infrastructure we'll guarantee 99.9% uptime performance with around the clock service and monitoring for any outages."
                    className='mb-4'
                  />
                  <ListArticle
                    icon='bug'
                    title='Bug Free'
                    description="We install bug reporting software in all our projects and will work with you to fix any bugs related to our code free of charge."
                    className='mb-4'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </SectionBody>
      </section>
    </Waypoint>
  )
}

export default IndexSectionWhy;

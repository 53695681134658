import React, { useState } from 'react';
import { DmcCompWelcome, Blob } from 'src/assets';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { BtnLink, NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const IndexSectionDeliver = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-left')}>
      <section id='deliver' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 1}}>
              <div className='mb-5'>
                <SectionPretitle>What we deliver</SectionPretitle>
                <SectionTitle className='mxw-80'>Exceptional results</SectionTitle>
                <SectionText>
                  As your partner, it's our job to navigate the project from start to finish while filling in the gaps.
                  After all, you're hiring us to ask the right questions, read between the lines, and turn your ideas into production-ready applications.
                  Rest assured, we'll do that and more while keeping your project on-time and within budget.
                </SectionText>
                <SectionBtns>
                  <NextLink to='/about' className='btn-text-primary'>Learn More</NextLink>
                </SectionBtns>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className=''>
                <Blob className='top-0 absolute z-0 fill-secondary o-100' />
                <LazyImg src={DmcCompWelcome} alt='Mobile project photos' className='relative z-1' />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default IndexSectionDeliver;

import React from 'react';
import LazyLoad from 'react-lazyload';

const Lazyload = (props) => {
	const { children } = props;

	return (
		<LazyLoad {...props}>{children}</LazyLoad>
	)
}

const LazyImg = (props) => {
	const { src, alt, height = 150, className = '', children } = props;

	return (
		<LazyLoad height={height}>
			<img src={src} alt={alt} className={className}/>
		</LazyLoad>
	)
}

export {
	Lazyload,
	LazyImg
}

// Docs
// https://github.com/twobin/react-lazyload

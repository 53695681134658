import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import ListArticle from 'src/interface/articles/list-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { GBraden, GRoss, GHands, GComputer2, Blob } from 'src/assets';

const IndexSectionProcess = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='process' className={`py-8 animate ${animation} ${className}`}>
        <SectionHeader>
          <Container>
            <Row>
              <Col sm={12} md={8}>
                <SectionPretitle>Our Playbooks</SectionPretitle>
                <SectionTitle className=''>Let's move fast together</SectionTitle>
                <SectionText>
                  We use our own flavor of agile that breaks projects down into smaller, more nimble sprints.
                  That way, you'll have plenty of opportunity to collaborate and share feedback while we push forward.
                </SectionText>
                <SectionBtns>
                  <NextLink to='/playbooks' className='btn-text-primary'>Learn More</NextLink>
                </SectionBtns>
              </Col>
            </Row>
          </Container>
        </SectionHeader>
        <SectionBody className='pt-5'>
          <Container>
            <Row>
              <Col md={6}>
                <Blob className='absolute z-0 fill-secondary o-100' />
              </Col>
              <Col md={6}>
                <Blob className='absolute z-0 fill-primary o-100' />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6} lg={4}>
                <ListArticle
                  icon='lightbulb'
                  title='Discovery'
                  description="We'll use this time to deep dive into your concept, explore oportunities, and establish concrete objectives."
                  className='mb-4'
                />
              </Col>
              <Col md={6} lg={4}>
                <ListArticle
                  icon='paint-roller'
                  title='Design'
                  description="We'll use this time to visualize your concept via flow-charts, wireframes, and coded prototypes."
                  className='mb-4'
                />
              </Col>
              <Col md={6} lg={4}>
                <ListArticle
                  icon='code'
                  title='Development'
                  description="We'll use this time to build features, test and optimize everything against the criteria we've set."
                  className='mb-4'
                />
              </Col>
              <Col md={6} lg={4}>
                <ListArticle
                  icon='box-check'
                  title='Deploy'
                  description="We'll setup staging and production environments with single-click deploys so your team can test and rollout features."
                  className='mb-4'
                />
              </Col>
              <Col md={6} lg={4}>
                <ListArticle
                  icon='comments'
                  title='Feedback'
                  description="We'll help you gather feedback from multiple channels and translate it into actionable intelligence."
                  className='mb-4'
                />
              </Col>
              <Col md={6} lg={4}>
                <ListArticle
                  icon='repeat'
                  title='Rinse & Repeat'
                  description="We'll work with you to establish a process that works for you and keeps us on track to accomplish your goals."
                  className='mb-4'
                />
              </Col>
            </Row>
          </Container>
        </SectionBody>
      </section>
    </Waypoint>
  )
}

export default IndexSectionProcess;

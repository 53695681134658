import React, { Fragment, useEffect, useState } from 'react';
import { withDark } from 'src/contexts/dark-context';
import { SplashWrapper, Splash } from 'src/interface/basics/splashes';
import AnimeWrapper from 'src/interface/vendors/anime-wrapper';
import { timeout } from 'src/utils/helpers';
import { MhlTriLeft, MhlTriCenterDark, MhlTriCenterLight, MhlTriRight, MhlTitle, MhlSubtitle } from 'src/assets';

const LogoAnimation = ({ dark, nextAction, className = '', children }) => {
  const [ animation, setAnimation ] = useState(false);
  const [ exit, setExit ] = useState(false);


  // Hooks
  useEffect(() => {
    animateIntro();
  }, [])


  // Methods
  async function animateIntro() {
    setAnimation(true);
  }

  async function animateOutro() {
    await timeout(1500);
    setExit(true);
  }

  async function finish() {
    setAnimation(false);
    nextAction();
  }



  // Render
  return (
    <Splash>
      <div className='relative flex-middle m-auto mxw-50'>
        {animation && (
          <Fragment>
            <div style={{zIndex: 10}}>
              <AnimeWrapper
                delay={exit ? 200 : 300}
                duration={500}
                scale={exit ? [1, 1] : [1, 1]}
                opacity={exit ? [1, 0] : [0, 1]}
                translateY={exit ? ['1rem', '-5rem'] : ['5rem', '1rem']}
                translateX={exit ? ['5.4rem', '0rem'] : ['0rem', '5.4rem']}
                easing='easeOutSine'>
                <MhlTriLeft className='fill-primary' />
              </AnimeWrapper>
            </div>

            <AnimeWrapper
              delay={exit ? 0 : 0}
              duration={500}
              scale={exit ? [1, 1] :  [1, 1]}
              opacity={exit ? [1, 0] : [0, 1]}
              translateY={exit ? ['0rem', '-5rem'] : ['-5rem', '0rem']}
              translateX={exit ? ['-1.5rem', '-1.5rem'] : ['-1.5rem', '-1.5rem']}
              easing='easeOutSine'>
              <MhlTriCenterDark className='fill-gray-800' />
            </AnimeWrapper>

            <AnimeWrapper
              delay={exit ? 400 : 600}
              duration={500}
              scale={exit ? [1, 1] : [1, 1]}
              opacity={exit ? [1, 0] : [0, 1]}
              translateY={exit ? ['2.05rem', '-5rem'] : ['5rem', '2.05rem']}
              translateX={exit ? ['-5.4rem', '0rem'] : ['0rem', '-5.4rem']}
              easing='easeOutSine'>
              <MhlTriRight className='fill-secondary' />
            </AnimeWrapper>
          </Fragment>
        )}
      </div>

      <div className='flex-middle m-auto'>
        {animation && (
          <AnimeWrapper
            delay={exit ? 400 : 900}
            duration={400}
            scale={exit ? [1, 1] : [1, 1]}
            opacity={exit ? [1, 0] : [0, 1]}
            translateY={exit ? ['0rem', '4rem'] : ['3rem', '0rem']}
            easing='easeOutSine'
            complete={() => exit ? finish() : animateOutro()}>
            <MhlTitle className='fill-gray-800' style={{ maxWidth: '200px' }} />
          </AnimeWrapper>
        )}
      </div>
    </Splash>
  )
}

export default withDark(LogoAnimation);

import React, { useState } from 'react';
import { GBraden, GRoss, GHands, GComputer2, Blob } from 'src/assets';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { BtnLink, NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const IndexSectionWho = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-left')}>
      <section id='who' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 1}}>
              <div className='mb-5'>
                <SectionPretitle>Who we are</SectionPretitle>
                <SectionTitle className=''>Your partner for the road ahead</SectionTitle>
                <SectionText>
                  We know technology can be overwhelming. Whether discussing the complexities of your platform or the latest framework - it's easy to make anyone's head spin.
                  That's why we focus on building partnerships founded on transparency and collaboration.
                  So you can focus on more important items while we do the heavy-lifting.
                </SectionText>
                <SectionBtns>
                  <NextLink to='/about' className='btn-text-primary'>Learn More</NextLink>
                </SectionBtns>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className=''>
                <Blob className='top-0 absolute z-0 fill-secondary o-300' />
                <Row>
                  <Col xs={6}>
                    <LazyImg src={GBraden} alt='Braden photo' className='relative z-1 mb-5'/>
                  </Col>
                  <Col xs={6}>
                    <LazyImg src={GRoss} alt='Ross photo' className='relative z-1 mb-5'/>
                  </Col>
                  <Col xs={6}>
                    <LazyImg src={GHands} alt='Hands photo' className='relative z-1 mb-5'/>
                  </Col>
                  <Col xs={6}>
                    <LazyImg src={GComputer2} alt='Computer photo' className='relative z-1 mb-5'/>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default IndexSectionWho;
